<template>
  <div>
    <div class="calendar-events-timeline">
      <b-alert show variant="warning" v-if="warnings.resourcesToBig">
        {{ $t('calendarPicker.resourcesToBigMessage', {maxResources:"100"}) }}
      </b-alert>
      <b-overlay :show="loading.resources || loading.events" rounded="sm">
        <FullCalendar :options="calendarOptions" ref="calendar" v-if="!loading.resources">
          <template v-slot:eventContent='arg'>
            <div v-if="arg.event.extendedProps.eventType !== 'cleaning'">
              <div class="fc-event-main-frame">
                <div class="fc-event-title-container" style="white-space: nowrap !important;" v-if="!arg.event.extendedProps.isFree">
                  <div class="fc-event-title fc-sticky" style="top: 0px;">
                    <div class="d-inline-block" v-bind:title="arg.event.title + (showPayments ? ` (${arg.event.extendedProps.metadata.price} €)`: '')">
                      <platform-logo v-bind:platform="arg.event.extendedProps.platform" size="21px" class="d-inline-block" />
                      {{ arg.event.title }}
                      <span v-if="showPayments">({{arg.event.extendedProps.metadata.price}} €)</span>
                    </div>
                  </div>

                </div>
                <div v-else class="text-secondary">
                  <i v-if="arg.event.extendedProps.isPriceSet" class="fa-solid fa-circle" :class="{
                  'text-warning':arg.event.extendedProps.isOverPrice,
                 'text-info': arg.event.extendedProps.isUnderPrice,
                 'text-success':!arg.event.extendedProps.isPriceDifferent
                }"
                     v-b-tooltip
                     :title="`userPrice: ${arg.event.extendedProps.prices.userPrice}€, price: ${arg.event.extendedProps.prices.price}€`"
                  ></i> &nbsp;
                  <span>{{ arg.event.title }}</span>
                </div>
              </div>
            </div>
            <div v-else>
<!--              {{arg.event}}-->
            </div>
          </template>
        </FullCalendar>
        <span v-if="allResources.length!==resources.length">Total of resources:  {{resources.length}} of {{ allResources.length }}</span>
        <span v-else>Total of resources:  {{resources.length}}</span>
      </b-overlay>
      <template v-if="selectedEvent != null">
        <b-popover :show.sync="dialogs.popovers"  v-bind:key="selectedEvent.id" :ref="'popover_cal_'+selectedEvent.id" :target="'ev-'+selectedEvent.id"
                   @show=" $root.$emit('bv::hide::popover');"
                   triggers="click focus" size="xl"
                   style="max-width: none">
          <template #title>
            <b-row>
              <b-col cols="9">
                {{ selectedEvent.title }}
              </b-col>
              <b-col cols="3">
                <div class="d-flex align-items-center justify-content-end">

                  <span class="mr-1"></span>
                  <b-link style="color: inherit;" @click="closeAllPopovers" :title="msg('Close')">
                    <feather-icon icon="XIcon"/>
                  </b-link>
                </div>
              </b-col>
            </b-row>

          </template>
          <div v-if="selectedEvent.platform!='BLOCKED'">
            <div class="event-popover-content">
              <treasureCalendarEvent :event="selectedEvent" @clientSelect="onClientSelect"/>
            </div>
          </div>
<!--          <div v-else>
            <b-button variant="success" @click="unblockDates(selectedEvent.id)">{{msg('Unblock')}}</b-button>
          </div>-->
        </b-popover>
      </template>
    </div>
  </div>
</template>

<script>


import FullCalendar from '@fullcalendar/vue'
import {mapActions} from "vuex";
import {getTimeLineDefaults} from "@/model/calendar/FullCalendarTimeLineBase"
import {findEventsGroupedNonUsedDays, getEventColor} from "@/model/calendar/CalendarFunctions"
import PlatformLogo from "@/components/widgets/PlatformLogo.vue";
// import treasureCalendarEvent from "@/components/widgets/calendar/treasureCalendarEvent.vue";
import mixinPopover from "@/mixins/mixinPopover";
import { BPopover } from 'bootstrap-vue'
import treasureCalendarEvent from "@/components/widgets/calendar/treasureCalendarEvent.vue";


export default {
  name: "TimeLineCalendar",
  mixins: [mixinPopover],
  props: {
    showPayments: {type: Boolean, default: false},
  },
  components: {
    treasureCalendarEvent,
    // eslint-disable-next-line vue/no-unused-components
    // treasureCalendarEvent,
    FullCalendar,
    // eslint-disable-next-line vue/no-unused-components
    PlatformLogo,
  },
  data() {
    return {
      loading: {
        resources: true,
        events: true
      },
      warnings:{
        resourcesToBig: false,
        events: false
      },
      buildingsMap: {},
      events: [],
      pricesMap: {},
      cal: {start: '', end: ''},
      currentDateSet: {start: '', end: ''},
      loadedExternalEvents: {},
      allResources: [],
      usageDaysByResources: {},
      resourcesPagination: {page: 1, amount: 50, total:0,  active: false},
      propertiesToBuildingsMap: {},
      dialogs: {
        popovers: false
      },
      selection: {
        start: null, end: null, propertyId: null
      },
      selectedEvent: null,
    }
  },
  created() {
    this.$root.$on('crm::branch-updated', this.refreshAll)
  },
  beforeDestroy() {
    this.$root.$off('crm::branch-updated', this.refreshAll)
  },
  mounted() {
    this.refreshBuildingResources()
  },
  computed: {
    calendarOptions() {
      // console.log('calendarOptions', this.resources)
      // let resources = [{id: '65cb49530636335d36c89e73', groupId: '1', title: 'Rennweg  51, ZIP:1030 CITY: Wien'}, {id: 'B', groupId: '1', title: 'Resource B'},]
      let $this = this;
      let base = getTimeLineDefaults($this.resources);
      return {
        ...base,
        navLinks: true,
        resources: $this.resources,
        events: this.onCalendarRefresh,
        eventDidMount: this.onEventDidMount,
        eventClick: this.onEventClick,
        datesSet: $this.onDateSet,
        resourceLabelDidMount:$this.onResourceLabelDidMount,
        resourceLabelClassNames: 'minimum-500',
        resourceLaneClassNames: 'minimum-500',
        resourceLabelContent: $this.updateResourceLabelContent,
        select(info) {
          let start = $this.$moment(info.start);
          let end = $this.$moment(info.end);
          $this.selection.propertyId = info.resource.id;
          $this.selection.start = start.format('YYYY-MM-DD');
          $this.selection.end = end.format('YYYY-MM-DD');
          $this.$emit('onSelect', {...$this.selection, startMoment: start, endMoment: end, property:$this.resources.find(r=>r.id === info.resource.id)})
        },
        selectable: true,
        height: '70vh',
      }
    },
    currentLang() {
      return this.$i18n.locale
    },
    resources(){
      let resources = JSON.parse(JSON.stringify(this.allResources)); //clone
      resources.forEach(r => {
        r.usageDays = this.usageDaysByResources[r.id];
      })
      if(this.resourcesPagination.active) {
        let pagination = this.resourcesPagination;
        return resources.slice((pagination.page - 1) * pagination.amount, pagination.page * pagination.amount)
      } else {
        return resources;
      }
    },
  },
  methods: {
    ...mapActions('calendar', ['getExternalCalendars', 'getTreasureCalendars', 'update']),
    ...mapActions('pricelabs', ['getPrices']),
    ...mapActions('building', ['getAllBuildingsBasics']),
    refreshAll() {
      let $this = this
      this.refreshBuildingResources()
          .finally(() => $this.refresh())
    },
    setResourcesPaginationPage(page) {
      this.resourcesPagination.page = page
      this.$emit('onPagination', this.resourcesPagination)
    },
    refreshBuildingResources() {
      let $this = this
      this.loading.resources = true
      return this.getAllBuildingsBasics({properties: true})
          .then(({buildings, realEstates}) => {
            // console.log('buildings', buildings)
            realEstates =  (realEstates?.filter(b=>b.address)  || []).sort((b1, b2) => b1.fullAddress.localeCompare(b2.fullAddress));
            $this.propertiesToBuildingsMap = realEstates.reduce((map, r) => ({...map, [r.id]: r.buildingId}), {})
            let buildingsMap = this.buildingsMap = buildings.reduce((map, b) => ({...map, [b.id]: b}), {})
            let resources = realEstates.map(r => {
              let building = buildingsMap[r.buildingId];
              return {
                id: r.id,
                groupId: building ? building.address: null,
                title: building ? `apt ${r.apt}` : r.fullAddress,
                // title:  r.fullAddress,
                isBuilding: false
              }
            });
            $this.allResources = resources
            $this.resourcesPagination.page = 1
            $this.resourcesPagination.total = resources.length
            $this.resourcesPagination.active = resources.length > $this.resourcesPagination.amount;
            // $this.warnings.resourcesToBig = resources.length > 100
            $this.$emit('onPagination', $this.resourcesPagination)
          }).finally(() => $this.loading.resources = false)
    },
    refresh() {
      let $this = this
      let request = {from: $this.cal.start, until: $this.cal.end, userid: -1, typeList: [6, 7]};
      return Promise.allSettled([
            this.fetchFromPrices(),
            this.getTreasureCalendars({filter: request, prices: true})
          ]
      ).then(result => Promise.resolve(result.map(r => r.status === "fulfilled" ? r.value : [])))
          .then(([prices, events]) => {
            let pricesMap = prices?.groupBy(p => p.realEstateId);
            $this.pricesMap = pricesMap
            $this.events = (events || []).map(e => ({...e, external: false}))
          })
          .finally(() => $this.loading.events = false)
    },
    fetchFromPrices(){
      let $this = this
      let realEstateIds = this.allResources.filter(r => r.isBuilding !== true).map(r => r.id)
      let start = this.$moment(this.cal.start).format('YYYY-MM-DD')
      let end = this.$moment(this.cal.end).format('YYYY-MM-DD')
      return this.getPrices({filter: {start, end, realEstateIds}});
    },
    onCalendarRefresh(info, successCallback, failureCallback) {
      // console.log('onCalendarRefresh', {info})
      // let range = convertRange(this.$moment, info.start, info.end)
      // console.log('onCalendarRefresh', {range})
      this.loading.events = true
      this.cal.start = this.$moment(info.start)
          .format('YYYY-MM-DD HH:mm')
      this.cal.end = this.$moment(info.end)
          .format('YYYY-MM-DD HH:mm')
      this.refresh()
          .then(() => successCallback(this.adaptEvents()), failureCallback)
    },
    getSelection() {
      return {...this.selection}
    },
    adaptEvents() {
      // console.log("adaptEvents", {events: this.events, pricesMap: this.pricesMap})
      let $this = this;
      let allPropertiesEvents = this.events.map(ev => ({
        ...ev,
        rawId: ev.id, title: ev.title, id: 'ev-' + ev.id,
        resourceId: ev.propertyId,
        start: ev.start, end: ev.end,
        totalDays: $this.$moment(ev.end)
            .diff($this.$moment(ev.start), 'days'),
        color: getEventColor(ev),
      }))
      /*let cleaningTime = allPropertiesEvents.map(ev => ({
        rawId: 'cleaning -' + ev.id, title: '', id: 'ev-cleaning-' + ev.id,
        resourceId: ev.propertyId,
        start: ev.end , end: ev.end.replace("11:","12:"),
        eventType: 'cleaning'
      }))
      console.log("events -> cleaningTime", cleaningTime)*/
      this.updateUsageDaysByResources(allPropertiesEvents)
      // console.log('adaptEvents', {all})
      return [...allPropertiesEvents, /*...cleaningTime*/];
    },
    updateUsageDaysByResources(allPropertiesEvents){
      let $this = this;
      $this.usageDaysByResources = findEventsGroupedNonUsedDays(allPropertiesEvents, 'resourceId', this.$moment, this.currentDateSet.start, this.currentDateSet.end,
          (day, resourceId) => $this.fillNonUsedDays(allPropertiesEvents, day, resourceId))
    },
    updateResourceLabelContent(info){
      let $this = this;
      // console.log("resourceLabelContent", info)
      let resource = $this.resources.find(r => r.id === info.resource.id);
      if(resource == null || resource.usageDays == null){
        return resource?.title
      }
      let pTitle = document.createElement('p');
      pTitle.classList.add('m-0', 'text-capitalize')
      pTitle.innerText = resource.title;

      let iconStatus = document.createElement('i');
      let iconColor = resource.usageDays.usage > 80 ? 'text-success' : resource.usageDays.usage > 50 ? 'text-warning' : 'text-danger';
      iconStatus.classList.add('fa-solid', 'fa-circle', iconColor);
      iconStatus.style= 'font-size: 0.5rem; margin-right: 0.5rem;'

      let pUsage = document.createElement('p');
      pUsage.classList.add('m-0', 'text-capitalize')
      pUsage.innerText = `${$this.$t('contractStats.totalCapacityDescription',{night: resource.usageDays.usedDays, total: resource.usageDays.totalDays, capacity: resource.usageDays.guests})}`;
      pUsage.prepend(iconStatus)
      let arrayOfDomNodes = [ pTitle, pUsage ]
      return { domNodes: arrayOfDomNodes }
    },
    fillNonUsedDays(allPropertiesEvents, day, resourceId) {
      let prices = this.pricesMap[resourceId];
      if (!Array.isArray(prices)) {
        return;
      }
      let dayStr = day.format('YYYY-MM-DD')
      let priceEvent = prices.find(p => p.date === dayStr)
      if (priceEvent == null || priceEvent.price <= 0) {
        return;
      }
      allPropertiesEvents.push({
        id: 'ev-price-' + resourceId + '-' + dayStr,
        title: `${priceEvent.price} €`,
        start: dayStr + ' 00:00:00',
        end: dayStr + ' 23:59:59',
        resourceId: resourceId,
        // color: 'green',
        borderColor: 'transparent',
        // color: 'green',
        isFree: true,
        isPrice: true,
        isPriceSet: priceEvent.userPrice > 0,
        isPriceDifferent: priceEvent.userPrice !== priceEvent.price,
        isOverPrice: priceEvent.userPrice > priceEvent.price,
        isUnderPrice: priceEvent.userPrice < priceEvent.price,
        prices: {
          userPrice: priceEvent.userPrice,
          price: priceEvent.price
        }
      });
    },
    onEventDidMount: function (eventInfo) {
      let $this = this;
      eventInfo.timeText = ''
      eventInfo.el.id = eventInfo.event.id
      eventInfo.event.classes = eventInfo.el.classList
    },
    onEventClick(eventInfo) {
      let $this = this;
      $this.dialogs.popovers = false;
      let event = this.events.find(ev => ev.id === eventInfo.event.extendedProps.rawId)
      // console.log('onEventClick', {eventInfo, event})
      this.selectedEvent = event;
      setTimeout(() => {
        $this.dialogs.popovers = true;
        // $this.$refs['popover_cal_' + event.id].show();
        $this.$forceUpdate()
      }, 200)
    },
    onDateSet(info){
      console.log('datesSet', info)
      this.currentDateSet.start = this.$moment(info.start).format('YYYY-MM-DD');
      this.currentDateSet.end = this.$moment(info.end).format('YYYY-MM-DD');
      if(!this.loading.events){
        this.adaptEvents()
      }
    },
    onClientSelect(id) {
      // console.log('calendar-> onClientSelect(' + id + ')')
      this.$root.$emit('crm::client::select', id)
    },
    test(arg) {
      // console.log('test', arg.event)
      return arg.event.title
    }
  }
}
</script>


<style lang="scss">
.calendar-events-timeline {
  .fc-datagrid-cell:not(.fc-resource-group) {
    .fc-datagrid-expander {
      display: none;
    }
  }
}
</style>
